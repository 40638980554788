<template lang="pug">
    section#conteudoImagem
        .wrapper
            picture
                source(:srcset="item.image.desktop.src", :media="index ? '(orientation: portrait)' : '(orientation: landscape)'" v-for="item, index in contents.content")
                img(:src="defaultImage.src", :alt="defaultImage.alt")
</template>

<script>
import { props } from '@/mixins/components'

export default {
  name: 'section-conteudo-imagem',
  props,
  computed: {
    defaultImage () {
      return this.contents.content[0].image.desktop
    }
  }
}
</script>

<style lang="stylus" scoped src="./ConteudoImagem.styl"></style>
